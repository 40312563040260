<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ documentTitle }}</h1>
      <div class="btns-container">
        <VasionButton
          id="cancel-button"
          class="add-cancel"
          name="cancel-button"
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>
        <VasionButton
          id="save-attributes-button"
          class="last-btn"
          :classProp="'primary'"
          :isFullWidth="false"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="md-layout toolbar-div">
        <div class="control-box">
          <VasionButton
            id="btnFirstPage"
            class="action-link"
            name="btnFirstPage"
            title="First Page"
            :icon="'VasionFirstPageIcon'"
            :isDisabled="disablePreviousButtons"
            @vasionButtonClicked="goToFirstPage"
          />
          <VasionButton
            id="btnPreviousPage"
            class="action-link"
            name="btnPreviousPage"
            title="Previous Page"
            :icon="'VasionPreviousPageIcon'"
            :isDisabled="disablePreviousButtons"
            @vasionButtonClicked="goToPreviousPage"
          />
          <VasionInput
            v-model="pageDisplay"
            :readOnly="true"
            inputType="blank-white"
            width="94"
            class="action-input"
          />
          <VasionButton
            id="btnNextPage"
            class="action-link"
            name="btnNextPage"
            title="Next Page"
            :icon="'VasionNextPageIcon'"
            :isDisabled="disableNextButtons"
            @vasionButtonClicked="goToNextPage"
          />
          <VasionButton
            id="btnLastPage"
            class="action-link"
            name="btnLastPage"
            title="Last Page"
            :icon="'VasionLastPageIcon'"
            :isDisabled="disableNextButtons"
            @vasionButtonClicked="goToLastPage"
          />
          <div class="divider" />
          <VasionButton
            id="zoom-in"
            class="action-link"
            name="zoom-in"
            title="Zoom In"
            :icon="'VasionZoomInIcon'"
            :isDisabled="!isDocumentLoaded"
            @vasionButtonClicked="zoomIn"
          />
          <VasionButton
            id="zoom-out"
            class="action-link"
            name="zoom-out"
            title="Zoom Out"
            :icon="'VasionZoomOutIcon'"
            :isDisabled="!isDocumentLoaded"
            @vasionButtonClicked="zoomOut"
          />
          <div class="divider" />
          <VasionButton
            id="form-field-button"
            class="action-link"
            name="form-field-button"
            title="Add Form Field"
            :icon="'VasionDocumentTemplateIcon'"
            :isDisabled="!isDocumentLoaded || selectedObjectID <= 0"
            @vasionButtonClicked="addNewAnnotation(annotationConfig.formField)"
          />
          <div class="divider" />
          <VasionDropList
            id="annotation-fontsize-dropdown"
            v-slot="slotItem"
            v-model="annotationFontSize"
            name="annotation-fontsize-dropdown"
            title="Annotation Font Size"
            width="120"
            type="plain-list"
            displayName="size"
            valueName="size"
            :showSearchField="false"
            :dataArray="annotationSizes"
            @input="markAsDirty"
          >
            {{ slotItem.item.size }}
          </VasionDropList>
        </div>
      </div>
      <div class="md-layout main-content">
        <div class="md-layout-item middle-section">
          <div class="mainImageDiv">
            <DocumentImage
              v-if="isDocumentLoaded"
              ref="mainImage"
              :key="currentPageNumber"
              :documentID="0"
              :pageNumber="currentPageNumber"
              :width="viewerWidth"
              :height="viewerHeight"
              :largeDefaultLoadSize="true"
              :context="'DocTemplateZones'"
              :imageEndpoint="'document/getFileImageURL'"
              :filePath="uploadedfilePath"
              @backgroundImageLoaded="setZoom"
              @fileImageLoaded="mainImageLoaded"
            />
            <div v-else class="upload-container">
              <div class="file-pond-block-wrapper non-dialog">
                <file-pond
                  ref="pond"
                  class="new-document"
                  :server="serverOptions"
                  :label-idle="filePondLabel"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="right-sidebar">
          <div v-if="zoneDetailsPanelType === annotationConfig.formField.subType">
            <div class="right-sidebar-title">
              Field Details
            </div>
            <VasionDropList
              v-if="!currentFormFieldData.userSignature"
              id="form-field"
              v-slot="slotItem"
              v-model="currentFormFieldData.selectedField"
              name="form-field"
              class="panel-component-bottom-padding"
              :dataArray="indexFields"
              title="FIELD"
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="markAsDirty(); setCurrentFieldName()"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionCheckbox
              id="user-signature"
              class="panel-component-bottom-padding"
              name="user-signature"
              :checked="currentFormFieldData.userSignature"
              @change="markAsDirty(); currentFormFieldData.userSignature = !currentFormFieldData.userSignature"
            >
              User Signature
            </VasionCheckbox>
            <VasionCheckbox
              id="bottom-align"
              class="panel-component-bottom-padding"
              name="bottom-align"
              :checked="currentFormFieldData.bottomAlign"
              @change="markAsDirty(); currentFormFieldData.bottomAlign = !currentFormFieldData.bottomAlign"
            >
              Bottom Align
            </VasionCheckbox>
            <VasionCheckbox
              id="right-align"
              class="panel-component-bottom-padding"
              name="right-align"
              :checked="currentFormFieldData.rightAlign"
              @change="markAsDirty(); currentFormFieldData.rightAlign = !currentFormFieldData.rightAlign"
            >
              Right Align
            </VasionCheckbox>
            <div v-if="!currentFormFieldData.userSignature" @focusin="setDeleteOnInput" @focusout="clearDeleteOnInput">
              <VasionInput
                id="form-field-name"
                v-model="currentFormFieldData.name"
                class="panel-component-bottom-padding"
                title="NAME"
                name="form-field-name"
                inputType="top-white"
                :required="true"
                @input="markAsDirty"
              />
              <hr class="separator">
              <div class="right-sidebar-title">
                Multi Select Details
              </div>
              <VasionInput
                id="form-field-group-name"
                v-model="currentFormFieldData.groupName"
                class="panel-component-bottom-padding"
                title="GROUP NAME"
                name="form-field-group-name"
                inputType="top-white"
                @input="markAsDirty"
              />
              <VasionInput
                id="form-field-selection-value"
                v-model="currentFormFieldData.selectionValue"
                class="panel-component-bottom-padding"
                title="SELECTION VALUE"
                name="form-field-selection-value"
                inputType="top-white"
                @input="markAsDirty"
              />
            </div>
          </div>
          <div v-else>
            <div class="right-sidebar-title">
              Template Details
            </div>
            <VasionInput
              id="template-name"
              v-model="name"
              class="panel-component-bottom-padding"
              title="TEMPLATE NAME"
              name="template-name"
              inputType="top-white"
              :required="true"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
              @input="markAsDirty"
            />

            <VasionDropList
              id="template-type"
              v-slot="slotItem"
              v-model="selectedtemplateType"
              name="template-type"
              class="panel-component-bottom-padding"
              :dataArray="templateTypes"
              title="TEMPLATE TYPE"
              type="plain-list"
              displayName="name"
              valueName="value"
              :required="true"
              @input="clearFolder"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <div id="template-type-selection">
              <VasionDropList
                v-if="selectedtemplateType.name === 'Workflow'"
                id="workflow"
                v-slot="slotItem"
                v-model="selectedWorkflow"
                name="workflow"
                class="panel-component-bottom-padding"
                :dataArray="workflows"
                title="WORKFLOW"
                type="plain-list"
                displayName="name"
                valueName="value"
                :required="true"
                @input="markAsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionDropList
                v-if="selectedtemplateType.name === 'Signature Template'"
                id="signature-templates"
                v-slot="slotItem"
                v-model="selectedSignatureTemplate"
                name="signature-templates"
                class="panel-component-bottom-padding"
                :dataArray="signatureTemplates"
                title="SIGNATURE TEMPLATES"
                type="plain-list"
                displayName="name"
                valueName="value"
                :required="true"
                @input="markAsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <div v-if="selectedtemplateType.name === 'Storage'" id="storage-folder-div">
                <VasionInput
                  id="storage-folder-name"
                  v-model="folderName"
                  title="STORAGE FOLDER"
                  name="storage-folder-name"
                  inputType="top-white"
                  :readonly="true"
                  :required="true"
                  @input="markAsDirty"
                />
                <VasionButton
                  id="browse-folder-button"
                  class="browse-button panel-component-bottom-padding"
                  :classProp="'primary'"
                  @vasionButtonClicked="toggleBrowseFolderDialog"
                >
                  Browse...
                </VasionButton>
              </div>
            </div>

            <VasionButton
              id="template-file-button"
              class="browse-button panel-component-bottom-padding"
              :classProp="'primary'"
              :isDisabled="!isDocumentLoaded"
              @vasionButtonClicked="toggleUploadDialog"
            >
              Change Template File...
            </VasionButton>

            <VasionDropList
              v-slot="slotItem"
              v-model="selectedObject"
              class="panel-component-bottom-padding"
              :dataArray="indexForms"
              :title="`${$formsLabel.toUpperCase()}`"
              type="plain-list"
              displayName="name"
              valueName="value"
              :required="true"
              @input="changeSelectedObject();"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionDropList
              v-if="selectedObject && selectedObject.value"
              v-slot="slotItem"
              v-model="lookupField"
              class="panel-component-bottom-padding"
              :dataArray="lookupFieldList"
              width="310"
              title="LOOKUP"
              placeholder="..."
              type="plain-list"
              displayName="name"
              valueName="id"
              @input="isDirty = true"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionButton
              id="static-fields-button"
              class="browse-button panel-component-bottom-padding"
              :classProp="'primary'"
              :isDisabled="selectedObjectID <= 0"
              @vasionButtonClicked="showStaticFieldsDialog = true"
            >
              Static Fields...
            </VasionButton>

            <VasionCheckbox
              id="inherit-object-from-form"
              class="panel-component-bottom-padding"
              name="inherit-object-from-form"
              :checked="inheritAllObjectDataFromForm"
              @change="toggleCheckbox('inheritAllObjectDataFromForm')"
            >
              Inherit All Object Data from Form
            </VasionCheckbox>

            <VasionCheckbox
              id="default-object-template"
              class="panel-component-bottom-padding"
              name="default-object-template"
              :checked="isDefaultTemplate"
              @change="toggleCheckbox('isDefaultTemplate')"
            >
              Default Object Template
            </VasionCheckbox>
          </div>
        </div>
      </div>
    </div>
    <md-dialog id="upload-new-file-dialog" class="dialog" :md-active.sync="showUploadDialog" :md-click-outside-to-close="false">
      <div class="upload-container">
        <div class="file-pond-block-wrapper">
          <file-pond
            ref="pond"
            class="new-document"
            :server="serverOptions"
            :label-idle="filePondLabel"
          />
        </div>
      </div>
      <div class="align-right">
        <VasionButton
          id="close-upload-dialog"
          :isFullWidth="false"
          :classProp="'secondary'"
          @vasionButtonClicked="toggleUploadDialog"
        >
          CLOSE
        </VasionButton>
      </div>
    </md-dialog>

    <VasionGeneralModal
      id="cancel-confirmation-div"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="'Your changes have not been saved, are you sure you want to cancel?'"
      :sync="showCancelModal"
      @confirmButtonClick="cancelModalOKClick()"
      @noButtonClick="cancelModalCancelClick()"
    />

    <VasionGeneralModal
      id="change-template-confirmation-div"
      :confirmButtonText="'CONFIRM'"
      :rejectButtonText="'CANCEL'"
      :modalType="'slot'"
      :sync="showChangeTemplateModal"
      @confirmButtonClick="cancelModalTemplateOKClick()"
      @noButtonClick="cancelModalTemplateCancelClick()"
    >
      <div v-if="isDirty" class="vasion-error-text">
        WARNING: Any changes you have not saved will be lost
      </div>
      <div>
        <md-radio
          v-model="clearZonesOnChangeTemplate"
          :value="false"
        >
          Replace file only (keep existing zones)
        </md-radio>
      </div>
      <div>
        <md-radio
          v-model="clearZonesOnChangeTemplate"
          :value="true"
        >
          Replace file and clear zones
        </md-radio>
      </div>
    </VasionGeneralModal>

    <VasionGeneralModal
      id="change-object-confirmation-div"
      :confirmButtonText="'REPLACE'"
      :rejectButtonText="'CANCEL'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="replaceMessage + '\n Would you like to proceed and force replacing the file?'"
      :sync="showReplaceDialogue"
      @confirmButtonClick="replaceTemplateModalOKClick()"
      @noButtonClick="replaceTemplateModalCancelClick()"
    />

    <VasionGeneralModal
      id="change-object-confirmation-div"
      :confirmButtonText="'YES'"
      :rejectButtonText="'NO'"
      :modalType="'message'"
      :buttonGroup="'center'"
      :message="`One or more fields from this ${$formsLabel} are in use. Are you sure you want to change ${$formsLabelPlural}?`"
      :sync="showChangeObjectsModal"
      @confirmButtonClick="changeObjectModalOKClick()"
      @noButtonClick="changeObjectModalCancelClick()"
    />

    <VasionGeneralModal
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'CONFIRM'"
      :modalType="'slot'"
      :sync="showBrowseFolder"
      @confirmButtonClick="folderDialogOK()"
      @noButtonClick="showBrowseFolder = false"
    >
      <div id="browse-folders-container">
        <VasionFolders />
      </div>
    </VasionGeneralModal>

    <VasionSnackbar
      id="document-uploaded-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <VasionStaticFields
      :objectID="selectedObjectID"
      :initialValues="staticFields"
      :sync="showStaticFieldsDialog"
      @closeModal="staticFieldsUpdated"
    />
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage.vue'
import { toBase64 } from '@/store/helperModules/storage.module'

export default {
  name: 'EditDocumentTemplate',
  components: {
    DocumentImage,
  },
  beforeRouteLeave(to, from, next) { // Navigation outside the app
    if (this.isDirty && to.name !== 'TheLogin') {
      this.routeTo = to
      this.showCancelModal = true
    } else if (!this.isDirty) {
      this.routeTo = null
      next()
    }
  },
  beforeRouteUpdate(to, from, next) { // Navigation within the app
    if (this.isDirty && to.name !== 'TheLogin') {
      this.routeTo = to
      this.showCancelModal = true
    } else if (!this.isDirty) {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      annotationConfig: {
        formField: { type: 'formField', color: '', subType: 'Form Field' },
      },
      annotationFontSize: { size: 'Auto' },
      annotationSizes: [
        { size: 'Auto' },
        { size: 8 },
        { size: 9 },
        { size: 10 },
        { size: 11 },
        { size: 12 },
        { size: 14 },
        { size: 16 },
        { size: 18 },
        { size: 20 },
        { size: 24 },
      ],
      clearZonesOnChangeTemplate: false,
      currentFormFieldData: {
        annGUID: '',
        bottomAlign: false,
        formFieldID: 0,
        groupName: '',
        name: '',
        pageNumber: 1,
        rightAlign: false,
        selectionValue: '',
        selectedField: {
          name: '',
          value: '',
        },
        userSignature: false,
      },
      currentPageNumber: 1,
      fileDataString: '',
      firstTimeImageLoad: true,
      folderId: null,
      folderName: '',
      formFieldDataArray: [],
      indexForms: [],
      inheritAllObjectDataFromForm: false,
      isDefaultTemplate: false,
      isDirty: false,
      lookupField: {},
      name: '',
      previousSelectedObject: {},
      replacementDocumentGUID: null,
      replaceMessage: '',
      selectedObject: {
        name: '',
        value: 0,
      },
      selectedSignatureTemplate: {
        name: '',
        value: 0,
      },
      selectedtemplateType: {
        name: '',
        value: 0,
      },
      selectedWorkflow: {
        name: '',
        value: 0,
      },
      serverOptions: { process: this.processHandler },
      showBrowseFolder: false,
      showCancelModal: false,
      showChangeObjectsModal: false,
      showChangeTemplateModal: false,
      showSnackbarBool: false,
      showReplaceDialogue: false,
      showStaticFieldsDialog: false,
      showUploadDialog: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      staticFields: [],
      templateId: 0,
      templateTypes: [],
      totalPages: 0,
      uploadedFileName: '',
      uploadedfilePath: '',
      viewerHeight: 2112,
      viewerWidth: 1632,
      zoneDetailsPanelType: 'Template',
    }
  },
  computed: {
    activeDocTemplateZone() { return this.$store.state.template.selectedDocTemplateZone },
    disableNextButtons() { return this.currentPageNumber >= this.totalPages },
    disablePreviousButtons() { return this.currentPageNumber <= 1 },
    documentTitle() { return 'Zone Configuration' },
    filePondLabel() {
      return `<span class="filepond-drag">Drag TIFF or PDF File Here</span>
              <br /><br/>
              <span class="filepond-drag-or">or</span>
              <br /><br />
              <span class="filepond-label-action">Upload New Document</span>`
    },
    indexFields() {
      // make a deep copy, otherwise we're updating the store value with the .unshift
      const fromStore = JSON.parse(JSON.stringify(this.$store.state.common.indexFields)).map((element) => {
        const trimField = {
          name: element.name,
          value: element.value,
        }

        return trimField
      })

      fromStore.unshift({
        name: '',
        value: '',
      })

      return fromStore
    },
    isDocumentLoaded() { return this.uploadedfilePath && this.uploadedfilePath !== '' },
    lookupFieldList() {
      const lookups = this.$store.state.fieldMapping.lookupList.filter(x => x.attributeFormID === this.selectedObject.value)
      lookups.unshift({ name: '[None]', id: 0 })
      return lookups
    },
    pageDisplay: {
      get: function () {
          return this.totalPages <= 0 ? '' : `${this.currentPageNumber}/${this.totalPages}`
      },
      set: function () {
      },
    },
    selectedFolder() { return this.$store.state.common.selectedFolder },
    selectedObjectID() { return this.selectedObject && this.selectedObject.value !== undefined ? this.selectedObject.value : 0 },
    signatureTemplates() {
      const { Values } = this.$store.state.digitalSignature.templateData
      return Values.map(template => {
        return { name: template.sName, value: template.iID }
      })
    },
    tempUploadedFile() { return this.$store.state.template.tempFile },
    workflows() { return this.$store.state.reporting.workflows },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {
    activeDocTemplateZone: function () {
      this.updateCurrentZoneData()
      this.displayCurrentZonePanel()
    },
    currentPageNumber: function () {
      // this essentially unselects the current annotation from a data perspective
      this.$store.dispatch('template/setSelectedDocTemplateZone', {
        uniqueId: '',
        subType: '',
      })
    },
  },
  async created() {
    const [indexForms, , , , templateTypesLocal] = await Promise.all([
      this.$store.dispatch('attributeForm/getForms'),
      this.$store.dispatch('reporting/getWorkflows'),
      this.$store.dispatch('fieldMapping/getDatabaseLookups', true),
      this.$store.dispatch('digitalSignature/getSignatureDocumentTemplates'),
      this.$store.dispatch('admin/getDocumentTemplateTypeList'),
    ])
    await this.$store.dispatch('attributeForm/getFormsForUser')

    this.indexForms = indexForms

    this.templateTypes = templateTypesLocal.Values.map(template => {
      return {
        name: template.sName,
        value: template.iID,
      }
    })

    this.initializeLookupField()

    await this.reloadDocument()
  },
  methods: {
    mainImageLoaded(imageData) {
      if ((this.viewerHeight > this.viewerWidth && imageData.height < imageData.width)
        || (this.viewerHeight < this.viewerWidth && imageData.height > imageData.width)) {
          const temp = this.viewerHeight
          this.viewerHeight = this.viewerWidth
          this.viewerWidth = temp
        }

      if (this.viewerHeight > imageData.height || this.viewerWidth > imageData.width) {
        this.viewerHeight = imageData.height
        this.viewerWidth = imageData.width
      }
      this.setZoom()
    },
    async setZoom() {
      await this.$store.dispatch('document/resetZoomValue')
      if (window.innerWidth <= 416) {
        this.$store.dispatch('document/setZoomValue', .2)
      } else if (window.innerWidth <= 1430) {
        this.$store.dispatch('document/setZoomValue', .3)
      } else if (window.innerWidth <= 1630) {
        this.$store.dispatch('document/setZoomValue', .4)
      } else if (window.innerWidth <= 1870) {
        this.$store.dispatch('document/setZoomValue', .44)
      } else {
        this.$store.dispatch('document/setZoomValue', .54)
      }
    },
    addNewAnnotation(config) { this.$store.dispatch('document/setAnnotationConfig', config) },
    cancel: function () {
      if (this.isDirty) {
        this.showCancelModal = true
      } else {
        this.$router.push({ name: 'DocumentTemplates' })
      }
    },
    cancelModalCancelClick() { this.showCancelModal = false },
    cancelModalOKClick: async function () {
      this.showCancelModal = false
      this.markAsClean()

      if (this.routeTo && this.routeTo.name !== '') {
        if (this.routeTo.params) {
          this.$router.push({ path: this.routeTo.path })
        } else {
          this.$router.push({ path: this.routeTo.path, params: this.routTo.params })
        }
      } else {
        this.$router.push({ name: 'DocumentTemplates' })
      }
    },
    cancelModalTemplateCancelClick() { this.showChangeTemplateModal = false },
    cancelModalTemplateOKClick() {
      this.showChangeTemplateModal = false
      this.showUploadDialog = true
    },
    changeObjectModalCancelClick() {
      this.showChangeObjectsModal = false
      this.selectedObject = this.previousSelectedObject
    },
    changeObjectModalOKClick() {
      this.showChangeObjectsModal = false
      this.previousSelectedObject = { ...this.selectedObject }
      this.staticFields = []
      this.initializeLookupField()
      this.isDirty = true
      this.getFieldList()
    },
    changeSelectedObject() {
      if (Object.keys(this.previousSelectedObject).length > 0 && (this.formFieldDataArray.length > 0 || this.staticFields.length > 0)) {
        this.showChangeObjectsModal = true
      } else {
        this.previousSelectedObject = { ...this.selectedObject }
        this.isDirty = true
      }
      this.getFieldList()
      this.formFieldDataArray = this.formFieldDataArray.map(field => {
        return {
          ...field,
          selectedField: {},
        }
      })
      this.initializeLookupField()
    },
    cleanAndValidateData() {
      this.updateCurrentZoneData()

      if (!this.formFieldDataArray) {
        this.formFieldDataArray = []
      }

      // remove any zones that do not have an annotation associated with it
      this.formFieldDataArray.filter((element) => {
        return element.annGUID !== ''
      })

      if (!this.formFieldDataArray || this.formFieldDataArray.length <= 0) {
        return true
      }

      let isValid = true
      this.formFieldDataArray.map((formField) => {
        if (!formField.name && !formField.userSignature) {
          isValid = false
        }

        return true
      })

      return isValid
    },
    async clearDeleteOnInput() { await this.$store.dispatch('digitalSignature/setDeleteOnInput', false) },
    clearFolder() {
      this.folderName = ''
      this.markAsDirty()
    },
    displayCurrentZonePanel() {
      this.zoneDetailsPanelType = this.activeDocTemplateZone.subType

      switch (this.zoneDetailsPanelType) {
        case this.annotationConfig.formField.subType:
          // eslint-disable-next-line no-case-declarations
          let found = this.formFieldDataArray.find(element => element.annGUID === this.activeDocTemplateZone.uniqueId)
          if (!found) {
            found = this.getEmptyFormField()
            found.annGUID = this.activeDocTemplateZone.uniqueId
          }

          this.currentFormFieldData = found
          break;
        default:
          this.currentFormFieldData = this.getEmptyFormField()
          break
      }
    },
    folderDialogOK() {
      if (this.selectedFolder && this.selectedFolder.item.FolderId > 0 && this.selectedFolder.isSelected === true) {
        this.folderId = this.selectedFolder.item.FolderId
        this.folderName = this.selectedFolder.item.Name
        this.isDirty = true
      } else {
        this.folderId = null
        this.folderName = ''
      }

      this.showBrowseFolder = false
    },
    getDetails: async function (docTemplate) {
      this.annotationFontSize = this.annotationSizes.find(s => s.size === docTemplate.annotationFontSize) || this.annotationSizes.find(s => s.size === 'Auto')
      this.templateId = docTemplate.documentTemplateID
      this.name = docTemplate.name
      this.selectedObject = { name: docTemplate.objectName, value: docTemplate.objectID }
      this.previousSelectedObject = { name: docTemplate.objectName, value: docTemplate.objectID }
      await this.$store.dispatch('common/getIndexFieldsForForm', this.selectedObject.value)
      this.folderId = docTemplate.folderID
      this.folderName = docTemplate.folderName
      this.selectedWorkflow = (docTemplate.wfid !== null) ? this.workflows.find(item => item.value === docTemplate.wfid) : { name: '', value: 0 }
      this.selectedSignatureTemplate = docTemplate.signatureDocumentTemplateID !== null ? this.signatureTemplates.find(item => item.value === docTemplate.signatureDocumentTemplateID) : { name: '', value: 0 }
      this.selectedtemplateType = docTemplate.templateDocType ? this.templateTypes.find(item => item.value === docTemplate.templateDocType) : { name: '', value: 0 }
      this.wfid = docTemplate.wfid
      this.lookupField = this.lookupFieldList.find(a => a.id === docTemplate.lookupID)

      if (!this.lookupField) {
        this.initializeLookupField()
      }

      this.uploadedfilePath = docTemplate.tempServerFilePath
      this.isDefaultTemplate = docTemplate.isDefaultTemplate
      this.inheritAllObjectDataFromForm = docTemplate.saveAllObjectData
      this.formFieldDataArray = docTemplate.formFieldList.map((item) => {
        return {
          formFieldID: item.formFieldID,
          pageNumber: item.pageNumber,
          name: item.name,
          selectedField: this.indexFields.find(field => field.value === item.uniqueFieldName),
          annGUID: item.annGUID,
          groupName: item.groupName,
          selectionValue: item.selectionValue,
          userSignature: item.userSignature,
          bottomAlign: item.bottomAlign,
          rightAlign: item.rightAlign,
        }
      })

      this.staticFields = !docTemplate.staticFieldsList ? [] : docTemplate.staticFieldsList.map(element => {
        const newElement = {
          fieldName: element.fieldName,
          value: element.value,
        }

        return newElement
      })

      this.updatePageCount()
    },
    getEmptyFormField() {
      const result = {
        annGUID: '',
        formFieldID: 0,
        groupName: '',
        name: '',
        pageNumber: this.currentPageNumber,
        bottomAlign: true,
        rightAlign: false,
        selectionValue: '',
        selectedField: {name: "", value: ""},
        userSignature: false,
      }

      return result
    },
    getFieldList() {
      if (this.selectedObject.name !== '' && this.selectedObject.value > 0) {
        this.$store.dispatch('common/getIndexFieldsForForm', this.selectedObject.value)
      }
    },
    goToFirstPage() { this.goToPage(1) },
    goToLastPage() { this.goToPage(this.totalPages) },
    goToNextPage() {
      if ((this.currentPageNumber + 1) <= this.totalPages) {
        this.goToPage(this.currentPageNumber + 1)
      }
    },
    async goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
        if (this.currentPage !== pageNumber) {
          await this.$store.dispatch('document/resetZoomValue')
        }
        this.currentPageNumber = pageNumber
      }
    },
    goToPreviousPage() {
      if (this.currentPageNumber > 1) {
        this.goToPage(this.currentPageNumber - 1)
      }
    },
    initializeLookupField() {
      this.lookupField = {
        name: '[None]',
        id: null,
      }
    },
    markAsClean() { this.isDirty = false },
    markAsDirty() { this.isDirty = true },
    // eslint-disable-next-line consistent-return
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
        if (!file.type.includes('tif')
          && !file.type.includes('pdf')) {
          this.snackbarTitle = 'Upload Failed'
          this.snackbarSubTitle = 'Template File only supports these file types: .tif, .tiff, .pdf'
          this.snackbarImage = false
          this.showSnackbarBool = true

          if (this.showUploadDialog === true) {
            this.showUploadDialog = false
          } else {
            this.uploadedfilePath = 'error.pdf'
            // The set timeout acts as a way to reset the upload display
            setTimeout(() => { this.uploadedfilePath = '' }, 500);
          }
        } else {
          this.uploadedFileName = file.name
          this.fileDataString = await toBase64(file)
          this.uploadedfilePath = ''
          const indexOf = this.fileDataString.indexOf(';base64,')
          if (indexOf >= 0) {
            this.fileDataString = this.fileDataString.substring(indexOf + 8)
          }

          progress(true, 0, 1024);
          load(file.name)

          if (this.formFieldDataArray.length > 0) {
            const payload = {
              name: this.uploadedFileName,
              documentTemplateID: this.templateId,
              deleteZones: this.clearZonesOnChangeTemplate,
              fileData: this.fileDataString,
            }
            let ret = await this.$store.dispatch('document/replaceDocumentTemplateFile', payload)
            if (!ret.replaced) {
              if (!ret.guid) {
                this.showSnackbarBool = true
                this.snackbarTitle = 'Error attempting to replace file'
                this.snackbarSubTitle = ret.message
                this.snackbarImage = false
              } else {
                this.replaceMessage = ret.message
                this.replacementDocumentGUID = ret.guid
                this.showReplaceDialogue = true
              }
            } else {
              this.showSnackbarBool = true
              this.snackbarTitle = 'Success'
              this.snackbarSubTitle = ret.message
              this.snackbarImage = true
              await this.reloadDocument()
            }
          } else {
            const payload = {
              name: this.uploadedFileName,
              fileData: this.fileDataString,
            }
            await this.$store.dispatch('template/uploadTempFile', payload)
            this.currentPageNumber = 1
            this.uploadedfilePath = this.$store.state.template.tempFile
            this.formFieldDataArray = []
            this.updatePageCount()
          }
          this.fileDataString = ''
          this.isDirty = true
          this.showUploadDialog = false
        }

        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        };
      } catch (err) {
        console.warn(err)
      }
    },
    async reloadDocument() {
      if (this.$route.params.templateId > 0) {
        this.currentPageNumber = 1
        const response = await this.$store.dispatch('template/getDocumentTemplate', this.$route.params.templateId)
        this.getDetails(response)
      }
    },
    async replaceTemplateModalCancelClick() {
      this.selectedObject = this.previousSelectedObject
      await this.reloadDocument()
      this.showReplaceDialogue = false
    },
    async replaceTemplateModalOKClick () {
      const payload = {
        guid: this.replacementDocumentGUID,
        documentTemplateID: this.templateId
      }
      let response = await this.$store.dispatch('admin/documentTemplateReplace', payload)
      if (response.data.ResultStatus !== 1) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Replacement failed'
        this.snackbarSubTitle = response.data.ResultMessage + 'Check Server Logs'
        this.snackbarImage = false
      }
      await this.reloadDocument()
      this.showReplaceDialogue = false
    },
    save: async function () {
      if (this.uploadedfilePath === ''
        || this.name === ''
        || this.name.trim() === ''
        || this.selectedObjectID <= 0
        || this.selectedtemplateType.name === ''
        || (this.selectedtemplateType.name === 'Signature Template' && this.selectedSignatureTemplate.name === '')
        || (this.selectedtemplateType.name === 'Storage' && this.folderName === '')
        || (this.selectedtemplateType.name === 'Workflow' && this.selectedWorkflow.name === '')) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Required fields missing'
        this.snackbarSubTitle = 'Please confirm all required fields have been entered for the template'
        this.snackbarImage = false
        return
      }
      if (!this.cleanAndValidateData()) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'One or More Zones are Invalid'
        this.snackbarSubTitle = 'Please confirm all required fields have been entered in each Zone'
        this.snackbarImage = false
        return
      }
      if (this.formFieldDataArray.length === 0) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Add Object Field'
        this.snackbarSubTitle = 'A template must contain at least one mapped field'
        this.snackbarImage = false
        return
      }

      const payload = {
          annotationFontSize: typeof this.annotationFontSize.size === 'number' ? this.annotationFontSize.size : null,
          documentTemplateID: this.templateId,
          name: this.name,
          objectID: this.selectedObjectID,
          objectName: this.selectedObject.name,
          folderID: this.folderId,
          folderName: this.folderName,
          wfid: (this.selectedWorkflow.value === 0) ? null : this.selectedWorkflow.value,
          lookupID: this.lookupField.id,
          tempServerFilePath: this.uploadedfilePath,
          signatureDocumentTemplateID: this.selectedSignatureTemplate?.value ? this.selectedSignatureTemplate.value : null,
          templateDocType: this.selectedtemplateType.value,
          isDefaultTemplate: this.isDefaultTemplate,
          saveAllObjectData: this.inheritAllObjectDataFromForm,
          formFieldList: this.formFieldDataArray.map((item) => {
              return {
                  formFieldID: item.formFieldID,
                  pageNumber: item.pageNumber,
                  name: item.name,
                  uniqueFieldName: item.selectedField.value,
                  annGUID: item.annGUID,
                  groupName: item.groupName,
                  selectionValue: item.selectionValue,
                  userSignature: item.userSignature,
                  rightAlign: item.rightAlign,
                  bottomAlign: item.bottomAlign,
              }
          }),
          staticFieldsList: this.staticFields,
      }
      const value = await this.$store.dispatch('template/saveDocumentTemplate', payload)

      if (value === 0) {
         this.showFailureSaveMessage('An error occurred during save.')
         return
      }

      this.isDirty = false
      this.showSuccessSaveMessage('Document template successfully saved.')
      setTimeout(() => {
         this.$router.push({ name: 'DocumentTemplates' })
      }, 2000)
    },
    async setDeleteOnInput() { await this.$store.dispatch('digitalSignature/setDeleteOnInput', true) },
    setCurrentFieldName() {
      this.currentFormFieldData.name = this.currentFormFieldData.selectedField.name
    },
    showFailureSaveMessage(messageDetail) {
      this.snackbarTitle = 'Unable to save'
      this.snackbarSubTitle = messageDetail
      this.snackbarImage = false
      this.showSnackbarBool = true
    },
    showSuccessSaveMessage(messageDetail) {
      this.snackbarTitle = 'Saved'
      this.snackbarSubTitle = messageDetail
      this.snackbarImage = true
      this.showSnackbarBool = true
    },
    staticFieldsUpdated(newStaticFields) {
      this.showStaticFieldsDialog = false
      if (newStaticFields == null) {
        return
      }

      this.staticFields = newStaticFields.filter(element => {
        return element.value !== ''
      })

      this.isDirty = true
    },
    updateCurrentZoneData() {
      switch (this.zoneDetailsPanelType) {
        case this.annotationConfig.formField.subType:
          if (this.currentFormFieldData?.annGUID) {
                  // remove the old data and replace with the updated
            this.formFieldDataArray = this.formFieldDataArray.filter((element) => {
              return element.annGUID !== this.currentFormFieldData.annGUID
            })

            if (!this.activeDocTemplateZone.previousWasDeleted) {
              this.formFieldDataArray.push(this.currentFormFieldData)
            }
          }
          break;
        default:
          break;
      }
    },
    updatePageCount: async function () {
      const pageCountpayload = {
        Value: this.uploadedfilePath,
      }
      this.$store.dispatch('document/resetZoomValue')
      this.totalPages = Number(await this.$store.dispatch('document/getFilePageCount', pageCountpayload))
    },
    toggleBrowseFolderDialog() { this.showBrowseFolder = !this.showBrowseFolder },
    toggleCheckbox(checkbox) {
      this.isDirty = true
      this[checkbox] = !this[checkbox]
    },
    toggleUploadDialog: function () {
      if (this.showUploadDialog === false && this.formFieldDataArray.length > 0) {
        this.showChangeTemplateModal = true
        return
      }
      this.showUploadDialog = !this.showUploadDialog
    },
    zoomIn() {
      if (this.zoomValue > 3) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'WARNING'
        this.snackbarSubTitle = 'Unable to zoom past this point.'
        this.snackbarImage = false
      } else {
        this.$store.dispatch('document/setZoomValue', true)
      }
    },
    zoomOut() {
      if (this.zoomValue <= 0.1) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'WARNING'
        this.snackbarSubTitle = 'Unable to zoom past this point.'
        this.snackbarImage = false
      } else {
        this.$store.dispatch('document/setZoomValue', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .mainImageDiv {
    padding: 16px;
    overflow: scroll;
    height: calc(100vh - 162px);
    text-align: center;
    width: 100%;
  }
  @media (max-width: $phone) {
    .mainImageDiv {
      padding: 20px;
    }
  }

  .panel-component-bottom-padding {
    padding-bottom: 16px;
    width: 100% !important;
  }

  .innerDiv {
    padding-top: 10px;
  }

  .document-title {
    font-family: $font-family-bold;
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black;
    text-align: left;
    padding-left: 8px;
  }

  .marginBottom {
    margin-bottom: 10px;
  }
  .divider {
    width: 1px;
    height: 24px;
    margin: 0px 10px;
    background-color: $divider-light-grey;
  }

  .toolbar-div {
    border-bottom: solid 1px $divider-light-grey;
    padding-bottom: 5px;
  }

  .control-box {
    margin: 0px 15px;
    display: flex;
    align-items: center;
  }

  .middle-section {
    background-color: $grey-25;
    height: calc(100vh - 291px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;

    .upload-container {
      width: 100%;
      padding: 10px;
      border-radius: 8px;
      border: dashed 1px $grey-300;
      display: flex;
      justify-content: center;

      .non-dialog {
        padding: 10px;
        width: 632px;
      }
    }

  }

  .right-sidebar {
    width: 336px;
    height: calc(100vh - 291px);
    padding: 16px 16px 0;
    box-shadow: -1px 0 0 0 $grey-75;
    overflow: auto;

    .right-sidebar-title {
      font-family: Archivo;
      font-size: 18px;
      font-weight: 500;
      padding-bottom: 8px;
    }
  }

  #upload-new-file-dialog {
    .align-right {
      text-align: right;
      padding: 5px 0px 10px 374px;
    }
  }

  #browse-folders-container {
    width: 400px;
    height: 300px;
    overflow: auto;
  }

  @media screen and (max-width: 1600px) {
    .middle-section {
      width: 600px;
    }
    .right-sidebar {
        width: 280px;
    }
  }

  @media screen and (max-width: 1108px) {
    .upload-container {
      width: 60%;
    }
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
